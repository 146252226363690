import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>A Family-Owned General Contracting Team</h1>
            </header>
            <div className="content">
                <p>
                    Shopping around for bids doesn't necessarily get you the best price. It gives each contractor the chance to use their in-person salesmanship to charge more. And when they're all charging more, you end up getting the best price from a group of over-charged bids. Sneaky, we know! We take a different approach. We offer upfront, transparent, haggle-free pricing, and compare it against those typical "over-charged" bids, so you know you're getting a great deal!
                </p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
