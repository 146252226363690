import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import favicon from '../assets/images/website-icon.png'
import roofing from '../assets/images/roofing.jpg'
import grading from '../assets/images/grading.jpg'
import masonry from '../assets/images/masonry.jpg'
import framing from '../assets/images/framing.jpg'
import drywall from '../assets/images/drywall.png'
import siding from '../assets/images/siding.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="GCW Residential General Contractors Serving North & South Carolina, as well as Virginia, Georgia, & Florida"
                    meta={[
                        { name: 'description', content: 'A Family-Owned General Contracting Team providing residential construction services to the South East USA.' },
                        { name: 'keywords', content: 'Residential Construction, Residential Builder, Site Clearing, Grading, Masonry, Framing, Siding, Roofing, Insulation, Drywall, Painting, Tree & Stump Removal' },
                    ]}
                    link={[
                        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${roofing})`}}>
                            <header className="major">
                                <h3>Roofing</h3>
                                <p>$300 per Square (Materials Included) | Learn More</p>
                            </header>
                            <Link to="/roofing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${grading})`}}>
                            <header className="major">
                                <h3>Site Clearing, Grading, & Erosion Control</h3>
                                <p>$7000 per Acre | Learn More</p>
                            </header>
                            <Link to="/grading" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${masonry})`}}>
                            <header className="major">
                                <h3>Crawlspaces, Brick Siding, & General Masonry</h3>
                                <p>$1.75 per Brick (Materials Included) | Learn More</p>
                            </header>
                            <Link to="/masonry" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${framing})`}}>
                            <header className="major">
                                <h3>Framing & SIPs Installation</h3>
                                <p>$8 per Square Foot (Lumber Not Included) | Learn More</p>
                            </header>
                            <Link to="/framing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${drywall})`}}>
                            <header className="major">
                                <h3>Insulation, Drywall, & Painting</h3>
                                <p>$30 per Square Foot (Materials Included) | Learn More</p>
                            </header>
                            <Link to="/drywall" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${siding})`}}>
                            <header className="major">
                                <h3>Siding (Vinyl, Lap, Fiber Cement, HardiePlank)</h3>
                                <p>Pricing Varies by Type of Siding | Learn More</p>
                            </header>
                            <Link to="/siding" className="link primary"></Link>
                        </article>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex